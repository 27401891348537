export const dataLink = [
    {
        id: 1,
        title : "Header.1",
        link : "/order"
    },
    {
        id: 2,
        title : "Header.2",
        link : "/createlogo"
    },
    {
        id: 3,
        title : "Header.3",
        link : "/aboutme"
    },
    {
        id: 4,
        title : "Header.4",
        link : "/contact"
    }
]

